<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Info-->
        <div class="product-Info">

            <!--s: Information-->
            <div class="product-information-box">

                <!--s: Top Basic information-->
                <div class="basic-box">

                    <!--s: Left Goods-->
                    <div class="basic-goods">
                        <div class="goods-img" :style="'background-image: url('+productInfoObj.images+')'"></div>
                        <a href="javascript:" class="goods-edit" @click="productImageVisible = true">Edit</a>
                    </div>
                    <!--e: Left Goods-->

                    <!--s: right Info-->
                    <div class="basic-info">
                        <div class="info-li-top">
                            <span class="top-title">ID<font>{{ productInfoObj.productId }}</font></span>
                            <div class="top-choice">
<!--                                <Radio-group>-->
<!--                                    <Radio label="Sold out"></Radio>-->
<!--                                    <Radio label="Low in stock"></Radio>-->
<!--                                    <Radio label="Back in stock"></Radio>-->
<!--                                </Radio-group>-->
                            </div>
                        </div>
                        <div class="info-li">
                            <span class="info-li-label">Brand:</span>
                            <div class="info-li-box">
                                <span class="text">{{ productInfoObj.brandName }}</span>
                            </div>
                        </div>
                        <div class="info-li">
                            <span class="info-li-label">Product:</span>
                            <div class="info-li-box">
                                <div class="info-text" :style="productDown?'height:auto':'height:25px'" @click="productTextVisible = true">{{ productInfoObj.name }}</div>
                                <a href="javascript:" class="down"></a>
                                <div class="info-product-modal" v-if="productTextVisible">
                                    <div class="product-modal-list">
                                        <ul>
                                            <li :key="key" v-for="(spuList,key) in productInfoObj.spuList" @click="onProductEditSelect(spuList)">
                                                <a href="javascript:" class="label-icon" :class="spuList.isTitle ===1? 'select':'selected'"></a>
                                                <p class="text">{{ spuList.channelName }}</p>
                                                <p class="text">{{ spuList.name }}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="product-modal-btn">
                                        <div class="product-modal-box">
                                            <a href="javascript:" @click="productTextVisible = false">Cancel</a>
                                            <a href="javascript:" @click="onTextSave">Save</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="info-li">
                            <span class="info-li-label">Price:</span>
                            <div class="info-li-box">
                                <span class="text">{{ productInfoObj.price }}</span>
                            </div>
                        </div>
                        <div class="info-li">
                            <span class="info-li-label">Details:</span>
                            <div class="info-li-box">
                                <div class="info-text" :style="detailsDown?'height:auto':'height:25px'" @click="detailsTextVisible = true">
                                    <div class="box" :key="key" v-for="(detailList,key) in productInfoObj.detailList">
                                        <span class="title">{{ detailList.channelName }}</span>
                                        <div class="info">{{ detailList.details }}</div>
                                    </div>
                                </div>
                                <a href="javascript:" class="down" @click="onBoxDown('details')"></a>
                                <div class="info-product-modal" v-if="detailsTextVisible">
                                    <div class="details-modal-list">
                                        <ul>
                                            <li :key="key" v-for="(spuList,key) in productInfoObj.spuList" @click="onProductDetailsSelect(spuList,key)">
                                                <a href="javascript:" class="label-icon" :class="spuList.isShow ===0? 'select':'selected'"></a>
                                                <p class="text">{{ spuList.channelName }}</p>
                                                <p class="text">{{ spuList.details }}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="product-modal-btn">
                                        <div class="product-modal-box">
                                            <a href="javascript:" @click="detailsTextVisible = false">Cancel</a>
                                            <a href="javascript:" @click="onDetailsSave">Save</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="info-size">
                            <div class="content">Size &nbsp;&nbsp;&nbsp;&nbsp;{{ productInfoObj.specs }}</div>
                        </div>
                        <div class="info-image-list" v-if="productImageVisible">
                            <div class="image-list">
                                <ul>
                                    <li :key="key" v-for="(imageList,key) in productInfoObj.imageList" @click="onProductSelect(imageList,key)" :style="imageList.isShow == 1?'background-color:#b9b9b9':''"><img :src="imageList.img"/></li>
                                </ul>
                            </div>
                            <div class="image-btn">
                                <div class="image-box">
                                    <a href="javascript:" @click="productImageVisible = false">Cancel</a>
                                    <a href="javascript:" @click="onImageSave">Save</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--e: right Info-->

                </div>
                <!--e: Top Basic information-->

                <!--s: Type Select-->
                <div class="basic-select">
                    <TypeDown v-if="productInfoObj.cid1List && productInfoObj.cid1List.length !==0" v-model="productInfoObj.cid1" title="Unit" :type="1" :width="60" :margin-right="15" :data="classificationObj" @on-select-type="onSelectType"/>
                    <TypeDown v-if="productInfoObj.cid2List && productInfoObj.cid2List.length !==0" v-model="productInfoObj.cid2" title="Category |" :type="2" :width="300" :margin-right="15" :data="productInfoObj.cid2List" @on-select-type="onSelectType"/>
                    <TypeDown v-if="productInfoObj.cid3List && productInfoObj.cid3List.length !==0" v-model="productInfoObj.cid3" title="Category ||" :type="3" :width="300" :margin-right="0" :data="productInfoObj.cid3List" @on-select-type="onSelectType"/>
                    <TypeDown v-if="productInfoObj.cid4List && productInfoObj.cid4List.length !==0" v-model="productInfoObj.cid4" title="Category |||" :type="4" :width="300" :margin-right="15" :data="productInfoObj.cid4List" @on-select-type="onSelectType"/>
                    <TypeDown v-if="productInfoObj.cid5List && productInfoObj.cid5List.length !==0" v-model="productInfoObj.cid5" title="Category IV" :type="5" :width="300" :margin-right="0" :data="productInfoObj.cid5List" @on-select-type="onSelectType"/>
                </div>
                <!--e: Type Select-->
                <!--s: Type Select-->
                <div class="basic-select">
                    <TypeDown :key="key" v-for="(attribute,key) in productInfoObj.attribute" v-model="attribute.selectId" :title="attribute.name" :titleId="attribute.id" attr :width="300" :margin-right="15" :data="attribute.attrList" @on-select-type="onSelectType"/>
                </div>
                <!--e: Type Select-->

            </div>
            <!--e: Information-->

            <!--s: Retail commodity information-->
            <div class="product-retail-information">
                <div class="product-retail-thead">
                    <span class="retail-cover">Cover</span>
                    <span class="retail-store">Store</span>
                    <span class="retail-status">Status</span>
                    <span class="retail-price">Price</span>
                    <span class="retail-nsp">N.S.P</span>
                    <div class="retail-all cursor" @click="onAllCheck">
                        <div class="retail-radio-box">
                            <i class="retail-radio" :class="checkStatus?'radio-select':'radio-selected'"></i>
                        </div>
                        <p>show</p>
                    </div>
                </div>
                <div class="product-retail-body">
                    <ul>
                        <li :key="key" v-for="(spuList,key) in productInfoObj.spuList">
                            <div class="retail-cover cover-img" :style="'background-image: url('+spuList.images+')'"></div>
                            <div class="retail-store">
                                <span class="retail-text">{{ spuList.channelName }}</span>
                                <div class="retail-text">
                                    <RateRound :number="4.5"/>
                                </div>
                            </div>
                            <div class="retail-status">
                                <span class="retail-text">in stock</span>
                                <span class="retail-text">{{ spuList.specs }}</span>
                                <span class="retail-text"><font :key="index" v-for="(specs,index) in spuList.specsArr">{{ specs }}{{ index+1 !==spuList.specsArr.length?',':'' }}</font></span>
                            </div>
                            <div class="retail-price">
                                <span class="retail-text decoration" v-if="spuList.nowprice!==''">{{ spuList.currency }} {{ spuList.nowprice }}</span>
                                <span class="retail-text">{{ spuList.currency }} {{ spuList.price }}</span>
                            </div>
                            <div class="retail-nsp">
                                <span class="retail-text" style="float:left;width:90px;min-height:25px">{{ spuList.promotion }}</span>
                            </div>
                            <div class="retail-show">
                                <div class="checkbox">
                                    <i class="retail-checkbox" :class="spuList.enabled === 1?'check-select':'check-selected'" @click="onCheck(spuList.enabled,key)"></i>
                                </div>
                            </div>
                            <div class="retail-edit">
                                <div class="edit">
                                    <a href="javascript:" v-if="!spuList.supVisible" @click="spuList.supVisible = !spuList.supVisible">Edit</a>
                                    <a href="javascript:" v-if="spuList.supVisible" @click="onSave(spuList)">Save</a>
                                </div>
                            </div>
                            <div class="retail-box" v-if="spuList.supVisible">
                                <div class="box">
                                    <span class="label">Different from main product?</span>
                                    <a href="javascript:" v-if="productInfoObj.spuList.length > 1" @click="onSeparate(spuList)">Separate</a>
                                </div>
                                <div class="box">
                                    <span class="label">Belongs to another another ID?</span>
                                    <div class="input-box"><input type="text" v-model="spuList.productId" class="input" placeholder="Product ID"></div>
                                    <a href="javascript:" @click="onMove(spuList)">Move</a>
                                </div>
                                <div class="box">
                                    <span class="label" style="width:76px">Shipping</span>
                                    <div class="input-box"><input type="text" v-model="spuList.upInfo.shippingCn" class="input"></div>
                                    <div class="input-box"><input type="text" v-model="spuList.upInfo.shippingEn" class="input"></div>
                                </div>
                                <div class="box">
                                    <span class="label" style="width:76px">Size guide</span>
                                    <div class="input-box"><textarea type="text" v-model="spuList.upInfo.modelInfoCn" class="textarea"/></div>
                                    <div class="input-box"><textarea type="text" v-model="spuList.upInfo.modelInfoEn" class="textarea"/></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="product-retail-btn">
                    <a href="javascript:">Check</a>
                    <a href="javascript:" @click="onLock">Lock</a>
                </div>
            </div>
            <!--e: Retail commodity information-->

        </div>
        <!--e: Info-->

    </div>
</template>
<script>
import ProductInfo from './info'
export default ProductInfo
</script>
<style lang="less">
@import "info";
</style>
