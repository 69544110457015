/**
 * 产品信息详情
 */
import Vue from 'vue'
import { RadioGroup,Radio } from 'iview';
Vue.component('Radio-group', RadioGroup);
Vue.component('Radio', Radio);
import { mapState } from "vuex";
import Header from '@/components/Header/header.vue'
import TypeDown from "@/components/TypeDown/typeDown.vue";
import RateRound from "@/components/RateRound/rateRound.vue";
import { getGroupInfoService,
    classification,
    updateGroupCategoryService,
    updateGroupInfoImageService,
    updateGroupInfoTitleService,
    updateGroupInfoDetailsService,
    updateGroupSpuModelService,
    updateGroupSeparateService,
    updateGroupMoveService,
    updateGroupOffService
} from "@/service/spu-group-service/index";
const ProductInfo = {
    name:'ProductInfo',
    components:{
        Header,
        TypeDown,
        RateRound
    },
    computed: {
        ...mapState({
            productId: state => state.product.productId,  //获取状态管理产品组id
        }),
    },
    data() {
        return {
            productDown:false,
            detailsDown:true,
            classificationObj:classification, //商品分类 一级菜单
            productImageVisible:false,
            productTextVisible:false,
            detailsTextVisible:false,
            productInfoObj:{}, //产品信息对象
            checkStatus:false,
            productTitle:{},
            productDetails:[],
            productInfoParams:{
                firstMenu:classification[0],
                secondMenu:'',
                tertiaryMenu:'',
                levelMenu:'',
                FiveMenu:'',
            }
        }
    },
    created() {
        this.onGroupInfo().then()
    },
    methods: {
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 下啦展示更多
         * @param name
         */
        onBoxDown(name){
            switch (name){
                case 'product':
                    this.productDown?this.productDown = false:this.productDown =true;
                    break;
                case 'details':
                    this.detailsDown?this.detailsDown = false:this.detailsDown =true;
                    break;
            }
        },
        onSelectType(obj){
            this.onGroupCategory(obj).then()
        },
        /**
         * 选中商品
         * @param obj
         */
        onProductSelect(obj,key) {
            if(obj.isShow === 1){
                this.productInfoObj.imageList[key].isShow = 0
            }else{
                this.productInfoObj.imageList[key].isShow = 1
            }
        },
        /**
         * 选择product
         * @param obj
         * @param key
         */
        onProductEditSelect(obj) {
            for(let i in this.productInfoObj.spuList){
                if(obj.id === this.productInfoObj.spuList[i].id){
                    this.productInfoObj.spuList[i].isTitle = 1
                }else{
                    this.productInfoObj.spuList[i].isTitle = 0
                }
            }
            this.productTitle = obj
        },
        /**
         * 选择详情
         * @param list
         */
        onProductDetailsSelect(list,key) {
            if(list.isShow === 1){
                this.productInfoObj.spuList[key].isShow = 0
            }else{
                this.productInfoObj.spuList[key].isShow = 1
            }
        },

        /**
         * 保存商品组title
         */
        onTextSave() {
            for(let i in this.productInfoObj.spuList){
                if(this.productInfoObj.spuList[i].isTitle === 1){
                    this.productTitle = this.productInfoObj.spuList[i]
                }
            }
            this.onUpdateGroupInfoTitle(this.productTitle).then()
        },
        /**
         * 保存商品组详情
         */
        onDetailsSave() {
            let spuIds = ''
            for(let i in this.productInfoObj.spuList){
                if(this.productInfoObj.spuList[i].isShow === 1){
                    spuIds += this.productInfoObj.spuList[i].id+','
                }
            }
            this.onUpdateGroupInfoDetails(spuIds).then()
        },
        /**
         * 保存商品组图片
         */
        onImageSave() {
            let ids = ''
            for(let i in this.productInfoObj.imageList){
                if(this.productInfoObj.imageList[i].isShow === 1){
                    ids += this.productInfoObj.imageList[i].id+','
                }
            }
            this.onUpdateGroupInfoImage(ids).then()
        },
        /**
         * 保存
         */
        onSave(obj) {
            this.onUpdateGroupSpuModel(obj).then()
        },
        /**
         * 分离
         * @param obj
         */
        onSeparate(obj) {
            this.onUpdateGroupSeparate(obj).then()
        },
        /**
         * 移动
         * @param obj
         */
        onMove(obj) {
            if(obj.productId === ''){
                alert('请输入Product ID')
                return
            }
            this.onUpdateGroupMove(obj).then()
        },
        /**
         * 选中
         * @param enabled
         * @param key
         */
        onCheck(enabled,key){
            enabled === 1?this.productInfoObj.spuList[key].enabled = 0:this.productInfoObj.spuList[key].enabled = 1

            for(let i in this.productInfoObj.spuList){
                if(this.productInfoObj.spuList[i].enabled === 0){
                    this.checkStatus = false;
                    return
                }else{
                    this.checkStatus = true
                }
            }

        },
        /**
         * 全选 / 反选
         */
        onAllCheck() {
            if(this.checkStatus){
                this.checkStatus = false
                for(let i in this.productInfoObj.spuList){
                    this.productInfoObj.spuList[i].enabled = 0
                }
            }else{
                for(let i in this.productInfoObj.spuList){
                    this.productInfoObj.spuList[i].enabled = 1
                }
                this.checkStatus = true
            }
        },
        /**
         * lock
         */
        onLock() {
            this.onUpdateGroupOff().then()
        },
        /**
         * 查询商品组信息
         * @returns {Promise<void>}
         */
        async onGroupInfo() {
            try {
                let params = {
                    'id':this.productId,
                }
                const {code,data} = await getGroupInfoService(params)
                this.productDetails = []
                if(code === 1){
                    this.productInfoObj = data
                    for(let i in data.supList){
                        if(data.supList[i].isTitle === 1){
                            this.productTitle = data.supList[i]
                        }
                        if(data.supList[i].isShow === 1){
                            this.productDetails.push(data.supList[i])
                        }
                    }
                    for(let i in this.productInfoObj.spuList){
                        if(this.productInfoObj.spuList[i].enabled === 1){
                            this.checkStatus = true
                        }else{
                            this.checkStatus = false
                        }
                    }
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改商品组图片
         * @returns {Promise<void>}
         */
        async onUpdateGroupInfoImage(ids) {
            try {
                let params = {
                    id:this.productId,
                    ids:ids
                }
                const {code} = await updateGroupInfoImageService(params)
                if(code === 1){
                    this.productImageVisible = false
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改商品组标题
         * @returns {Promise<void>}
         */
        async onUpdateGroupInfoTitle(obj) {
            try {
                let params = {
                    id:this.productId,
                    spuId:obj.id
                }
                const {code} = await updateGroupInfoTitleService(params)
                if(code === 1){
                    this.productTextVisible = false
                    this.productInfoObj.name = this.productTitle.name
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改商品组详情
         * @returns {Promise<void>}
         */
        async onUpdateGroupInfoDetails(spuIds) {
            try {
                let params = {
                    id:this.productId,
                    spuIds:spuIds
                }
                const {code} = await updateGroupInfoDetailsService(params)
                if(code === 1){
                    this.detailsTextVisible = false
                    this.onGroupInfo().then()
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 查询商品组信息
         * @returns {Promise<void>}
         */
        async onGroupCategory(obj) {
            try {
                let params = {}
                if(obj.attr){
                     params = {
                        'id':this.productId,
                        'attrId':obj.titleId,
                        'selectId':obj.id
                    }
                }else{
                    params = {
                        'id':this.productId,
                    }
                    if(obj.cid === 1){
                        params.cid1 = obj.id
                    }
                    if(obj.cid === 2){
                        params.cid2 = obj.id
                    }
                    if(obj.cid === 3){
                        params.cid3 = obj.id
                    }
                    if(obj.cid === 4){
                        params.cid4 = obj.id
                    }
                }
                const {code,data} = await updateGroupCategoryService(params)
                if(code === 1){
                    this.productInfoObj = data
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改商品组详情
         * @returns {Promise<void>}
         */
        async onUpdateGroupSpuModel(obj) {
            try {
                let params = obj.upInfo
                const {code} = await updateGroupSpuModelService(params)
                if(code === 1){
                    alert('successful')
                    this.onGroupInfo().then()
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改商品组详情 分离
         * @returns {Promise<void>}
         */
        async onUpdateGroupSeparate(obj) {
            try {
                let params = {
                    id:this.productId,
                    supId:obj.id
                }
                const {code} = await updateGroupSeparateService(params)
                if(code === 1){
                    alert('successful')
                    this.$router.push('/product/list')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改商品组详情 移动
         * @returns {Promise<void>}
         */
        async onUpdateGroupMove(obj) {
            try {
                let params = {
                    id:this.productId,
                    productId:obj.productId,
                    supId:obj.id
                }
                const {code} = await updateGroupMoveService(params)
                if(code === 1){
                    alert('successful')
                    this.$router.push('/product/list')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改信息列表发布
         * @returns {Promise<void>}
         */
        async onUpdateGroupOff() {
            try {
                let params = this.productInfoObj
                const {code} = await updateGroupOffService(params)
                if(code === 1){
                    alert('successful')
                    this.onGroupInfo().then()
                }
            }catch (error){
                console.log(error)
            }
        },
    },
}
export default ProductInfo
