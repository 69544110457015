/**
 * 圆形进度图
 * @type {{data(): {}}}
 */
const rateRound = {
    name:'rateRound',
    props:{
        /**
         * 数量
         */
        number:{
            type:Number
        },
    },
    data(){
        return {
        }
    },
    methods:{
    },
}
export default rateRound
