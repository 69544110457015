<template>
    <div class="rate-round">
        <div class="round-selected">
            <span :key="key" v-for="key in 10"></span>
        </div>
        <div class="round-select">
            <span :key="key" v-for="key in parseInt(number)"></span>
            <span class="semi" v-if="number%1!==0"></span>
        </div>
    </div>
</template>
<script>
import RateRound from './rateRound'
export default RateRound
</script>
<style lang="less">
@import "rateRound";
</style>
